<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            Lender Contact Information
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </h3>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <v-expansion-panels :value="0">
            <v-expansion-panel style="background-color: transparent">
              <v-expansion-panel-header>
                <h3>
                  Lender Contact Information
                  <v-icon
                    class="ml-4"
                    style="cursor: pointer"
                    @click="
                      $router.push({
                        path: `/dashboard/lenderContacts/edit/${lenderContactData.id}`,
                      })
                    "
                    >mdi-account-edit-outline</v-icon
                  >
                </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table dense>
                  <tbody>
                    <tr>
                      <td>Lender Contact Name</td>
                      <td>
                        {{ lenderContactData.firstName }}
                        {{ lenderContactData.lastName }}
                      </td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{{ lenderContactData.email }}</td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td>{{ lenderContactData.phone }}</td>
                    </tr>
                    <tr>
                      <td>Contact Role</td>
                      <td>{{ lenderContactData.contactRole }}</td>
                    </tr>
                    <tr>
                      <td>Send Email Apps</td>
                      <td>
                        {{
                          lenderContactData.emailOpportunities | booleanString
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Active</td>
                      <td>{{ lenderContactData.active | booleanString }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-center mt-5">
          <v-btn
            dark
            @click="
              $router.push({
                path: `/dashboard/lender/read/${lenderContactData.lenderId}`,
              })
            "
            >Back To Lender Details</v-btn
          >
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-5">
        <v-col xs="12" xl="6">
          <v-expansion-panels>
            <v-expansion-panel style="background-color: transparent">
              <v-expansion-panel-header>
                <h3>
                  Lender Contact Notes
                  <v-icon
                    class="ml-4"
                    style="cursor: pointer"
                    @click.native="addNote"
                    >mdi-notebook-plus-outline</v-icon
                  >
                </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <insert-note
                  v-if="addNoteActive"
                  :lenderContactId="lenderContactId"
                  :insertToTable="'lenderContact'"
                  @closeNote="closeNote"
                  @refreshNotes="refreshNotes"
                ></insert-note>

                <div v-if="loadingNotes">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
                <div v-if="!loadingNotes">
                  <div
                    v-for="lenderContactNote in lenderContactNotes"
                    :key="lenderContactNote.id"
                  >
                    <note
                      :noteProp="lenderContactNote"
                      :noteType="'lenderContactNote'"
                      @refreshNotes="refreshNotes"
                    ></note>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";

export default {
  name: "LenderContactRead",
  props: ["lenderContactId"],
  components: {
    insertNote: () => import("@/components/note/InsertNote.vue"),
    Note: () => import("@/components/note/Note.vue"),
  },
  data() {
    return {
      loadingNotes: false,
      addNoteActive: false,
      formLoading: false,
      componentLoading: true,
      submitError: "",
      formRules: {
        valid: false,
        required: [(value) => !!value || "This is a required field."],
      },
      lenderContactData: {},
      lenderContactNotes: {},
    };
  },
  methods: {
    async refreshNotes() {
      await API()
        .get(`/lenderContactNotes/${this.lenderContactId}`)
        .then((res) => {
          console.log(res);
          this.lenderContactNotes = res.data;
          this.loadingNotes = false;
          this.closeNote();
        })
        .catch((e) => {
          console.log("the catch block in refreshnotes is firing");
          console.log(e);
        });
    },
    addNote() {
      this.addNoteActive = true;
    },
    closeNote() {
      this.addNoteActive = false;
    },
  },
  mounted() {
    API()
      .get(`/lenderContact/read/${this.lenderContactId}`)
      .then((res) => {
        this.lenderContactData = res.data.recordsets[0][0];
        this.lenderContactNotes = res.data.recordsets[1];
        this.componentLoading = false;

        console.log("lenderContactData.id");
        console.log(this.lenderContactData.id);
      })
      .catch((e) => {
        console.log(e);
      });
    console.log(
      "mounted this.lenderContactData" + JSON.stringify(this.lenderContactData)
    );
  },
  filters: {
    booleanString(x) {
      if (x == true) {
        return "Yes";
      } else {
        return "No";
      }
    },
  },
};
</script>

<style>
</style>